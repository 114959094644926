import { Col, Container, Row } from 'react-bootstrap';
import MainCarousel from './Carousel/Carousel';
import './HomePage.css'

export const HomePage: React.FC = () => {
    return (
        <>
            <Container className="containBack" style={{maxWidth: "100%", padding: "0 5% 0 5%", margin: 0}}>
                    <Container style={{textAlign: 'center', display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Row>
                        

                        {/* <img id="event-space" className='mt-4' src="https://saucewestend.s3.us-east-2.amazonaws.com/Sauce_SpecialEventDinner.JPG" /> */}
                        <div className="" style={{justifyContent: 'center'}}>
                            <div className="col-12 pt-3">
                                <img src="/saucelogo.svg" alt="logo" style={{width: '100%', maxWidth: '700px'}} />
                            </div>
                            {/* <div className="p-3">
                                <a href="/menu" ><Button size="lg" variant="light">Menu</Button></a>
                            </div> */}
                        </div>
                        <div className='justify-content-center' style={{display: 'flex'}}>
                            <div id="circle_v13_wrap" style={{width: "calc(250px * 1)", height: "calc(215px * 1)"}}><div id="circle_v13" tabIndex={0}><div id="circletype_v13_brand_name" className="autoFontSize" style={{fontSize:"calc(16px * 1);"}}><a target="_blank" href='https://www.restaurantji.com/pa/brodheadsville/sauce-/' className="ahref_emprty_area ahref_v13_brand_name" id="ahref_v13_brand_name">Sauce</a></div><a href="https://www.restaurantji.com/pa/brodheadsville/sauce-/" target="_blank" id="rewards_link">Restaurantji</a></div></div><script src="https://cdn6.localdatacdn.com/badges/restaurantji/js/circletype.js"></script><script>new CircleType(document.getElementById("ahref_v13_brand_name")).radius(350)</script>
                        </div>
                    
                        <div className="text-white">
                            <h1 style={{fontSize: '3rem'}}>“Come for the food stay for the friendship”</h1>
                            <div>
                                <p style={{margin: 0, fontSize: '1.6rem'}}>Casual Dining</p>
                                <p style={{margin: 0, fontSize: '1.6rem'}}>Take out  ~  Eat In  ~  Catering</p>
                                <p style={{margin: 0, fontSize: '1.6rem'}}>True Passion   True Ingredients   True Flavor</p>
                            </div>
                            <div>
                                <h1 style={{fontSize: '2rem'}}>109 Marshall Lane</h1>
                                <h1 style={{fontSize: '2rem'}}>Brodheadsville Pa 18322</h1>
                                <h1 style={{fontSize: '2rem'}}>(570) 994-4134</h1>
                            </div>
                            <p> Behind Truist bank off of rt. 209 in the old Rita’s building.</p>
                        </div>
                    </Row>
                    </Container>
                    
                    <div className="white p-3 mt-4 mb-4">
                        <p>Sauce Casual Dining Restaurant is a locally and family-owned business. Providing a comfortable laid-back atmosphere Where all our guests are treated like family! 
                            We put an emphasis on good quality comfort food and warm friendly service.
                            We are dedicated to providing our guests with an unforgettably delicious meal at an affordable price.
                        </p>
                        <p><span style={{textDecoration: 'underline'}}>Owner Margaret La Fiura</span> - When I cook, I cook with my heart…with love. I enjoy cooking for and feeding people. It has been my passion for as long as I can remember. Creating large holiday meals with my grandmother and Mother was so joyful. The excitement of creating all the traditional family dishes and gathering with family and friends was pure happiness. I wanted to recreate that feeling and share my love with everyone! And so Sauce was born!!</p>
                    </div>
                    <Row className="justify-content-center mt-3 mb-5">
                        <Col xs={12} sm={12} lg={6}>
                            {MainCarousel(1, 11)}
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            {MainCarousel(12, 18)}
                        </Col>
                    </Row>
            </Container>
            <Row className="justify-content-center text-align-center text-white" style={{width: '100%'}}>
                <div className="footer mt-5">
                <h5>
                    109 Marshall Lane
                    Brodheadsville Pa 18322
                    (570) 994-4134
                </h5>
                </div>
            </Row>
        </>
    )
}