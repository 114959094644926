import { Container, Image, Row } from 'react-bootstrap';

export const Gallery: React.FC = () => {
    const items = [];
    for (let i = 1; i <= 19; i++){
        items.push(
            <div className="col-lg-3 col-sm-12 mb-3">
                <Image rounded fluid src={`photos/${i}.jpg`} />
            </div>
        );
    };

    return (
        <>
            <Container className="containBack" style={{maxWidth: "100%", padding: "0 5% 0 5%", margin: 0}}>
                    <Container style={{textAlign: 'center', display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Row>
                        <h1 className="text-white mt-5 pt-5">Gallery</h1>
                        <div className='row'>
                            {items}
                        </div>
                    </Row>
                    </Container>
            </Container>
            <Row className="justify-content-center text-align-center text-white" style={{width: '100%'}}>
                <div className="footer mt-5">
                <h5>
                    109 Marshall Lane
                    Brodheadsville Pa 18322
                    (570) 994-4134
                </h5>
                </div>
            </Row>
        </>
    )
}