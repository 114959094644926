import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from './components/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Menu } from './components/Menu/Menu';
import { About } from './components/About/About';
import { Gallery } from './components/Gallery/Gallery';

function App() {
  return (
    <>
      <Navbar className="myNav" bg="dark" variant="dark" expand="lg">
      <Container style={{maxWidth: "100%", padding: "0 5% 0 5%", margin: 0}}>
          <Navbar.Brand href="/">
            <img src="/saucelogo.svg" alt="logo" style={{height: '50px'}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
              {/* <Nav.Link href="#gift">Weekly Specials</Nav.Link> */}
              <Nav.Link href="/menu">Menu</Nav.Link>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              {/* <Nav.Link href="#catering">Contact Us</Nav.Link> */}
          </Nav>
          </Navbar.Collapse>
      </Container>
      </Navbar>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/catering" element={<Menu />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
