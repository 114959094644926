import { Image, Col, Container, Row } from 'react-bootstrap';
// import { readBuilderProgram } from 'typescript';
import './About.css'

export const About: React.FC = () => {
    return (
        <div id="about">
            <Container className="containBack" style={{maxWidth: "100%", padding: "0 5% 0 5%", margin: 0, height: '100%'}}>
                    <Container style={{textAlign: 'center', display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Row>
                        <h1 className="text-white mt-5 pt-5">About Us</h1>
                        <div className="text-white pt-3 mt-3" style={{backgroundColor: 'rgb(200, 100, 52, .4)'}}>
                            <p>The La Fiura family and partners in Sauce have been part of the Pleasant Valley community for 23 years. We have worked here & raised our children here. 
                                We have been serving the West End and surrounding communities for the past 8 years with our former food cart pop up business “Rice Around The World” creating authentic rice dishes and more from around the globe. Opening a locally based restaurant has been a dream for many years.
                                We invite you to bring your family and friends … relax in our indoor dining area or on our outdoor patio enjoy the beauty that the Pocono’s offers. Come on out… Let us cook for you!
                            </p>
                        </div>
                    </Row>
                    </Container>
            </Container>
                    <Row>
                        <Col xs={12} lg={12} className="mb-3 mt-3 frame">
                            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/BEUjjUe_umY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mt-3">
                            <Image fluid src="./Photo Collage Food.jpg" />
                        </Col>
                        <Col xs={12} lg={6} className="mb-3 mt-3">
                            <Image fluid src="./Best Food Facebook Post.jpg" />
                        </Col>
                    </Row>
            <Row className="justify-content-center text-align-center text-white" style={{width: '100%'}}>
                <div className="footer mt-5">
                <h5>
                    109 Marshall Lane
                    Brodheadsville Pa 18322
                    (570) 994-4134
                </h5>
                </div>
            </Row>
        </div>
    )
}