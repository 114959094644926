import { Row } from 'react-bootstrap';
import './Menu.css'
import { useEffect } from 'react';

export const Menu: React.FC = () => {
      function getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.platform || window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(platform)) {
          os = 'Linux';
        }
      
        return os;
      }

    useEffect(() => {
        if (getOS() === "iOS") {
            window.location.replace("https://saucewestend.s3.us-east-2.amazonaws.com/New+Menu+No+Breakfast.pdf")
        }
    }, [useEffect])

     
    return (
        <>
            <Row style={{width: "100%"}}>
                { getOS() !== 'iOS' &&
                    <iframe src="https://saucewestend.s3.us-east-2.amazonaws.com/New+Menu+No+Breakfast.pdf" width="100%" />
                }
            </Row>
        </>
    )
}