import Carousel from 'react-bootstrap/Carousel';

function MainCarousel(start:number=1, end:number=12) {
    const items = [];
    for (let i = start; i <= end; i++){
        items.push(
        <Carousel.Item>
            <img
              className="d-block w-100"
              src={`photos/${i}.jpg`}
              alt="Slide"
            />
            {/* <Carousel.Caption>
            </Carousel.Caption> */}
          </Carousel.Item>)
    }
  return (
    <Carousel indicators={false}>
        {items}
    </Carousel>
  );
}

export default MainCarousel;